import { sendMessageToIframe } from './iframeCommunication'

const observePageVisibility = (listener: (isPageVisible: boolean) => void) => {
  const handleVisibilityChange = () => listener(document.visibilityState === 'visible')
  document.addEventListener('visibilitychange', handleVisibilityChange)
  handleVisibilityChange()
}

export const syncPageVisibility = (iframe: HTMLIFrameElement) => {
  observePageVisibility(isPageVisible => {
    sendMessageToIframe({ type: 'PAGE_VISIBILITY_CHANGE', value: isPageVisible }, iframe)
  })
}
