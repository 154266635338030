import * as R from 'remeda'
import {
  getDefaultIntegrations,
  makeFetchTransport,
  defaultStackParser,
  BrowserClient,
  Scope,
  EventHint,
} from '@sentry/browser'
import { endpoint, sentry } from '@chilipiper/config'
import { handlePageActivity } from '../../shared/handlePageActivity'
import { MessageToEmbed } from '../../shared/types'
import {
  listenOnUrlUpdates,
  storeAndSendHref,
  storeAndSendMaxScrollPercentage,
  storeAndSendUserTimeOnPage,
} from './helpers'
import { handleMessage, iframeOrigin, iframeUrl, sendMessageToIframe } from './iframeCommunication'
import { sendIsNarrowScreen } from './isNarrowScreen'
import { syncPageVisibility } from './isPageVisible'

const createChatIframe = (
  workspaceId: string,
  tenantId: string,
  playbookId?: string,
  zIndex?: number
) => {
  const iframe = document.createElement('iframe')
  const queryParams = new URLSearchParams()
  queryParams.set('embedOrigin', window.location.origin)
  queryParams.set('workspaceId', workspaceId)
  queryParams.set('tenantId', tenantId)
  if (playbookId) {
    queryParams.set('playbookId', playbookId)
  }

  iframe.src = `${iframeUrl}?${queryParams.toString()}`
  iframe.id = 'chili-chat'
  iframe.title = 'Chat'
  iframe.style.cssText = `
      border: none;
      position: fixed;
      z-index: ${zIndex ?? 99999999};
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      max-height: 728px;
      background: transparent;
    `

  const style = document.createElement('style')
  style.innerHTML = `
  @media (max-width: 449px) {
    #chili-chat.expanded {
      width: 100% !important;
      height: 100% !important;
      max-height: 100% !important;
      bottom: 0 !important;
      right: 0 !important;
    }
  }
  `
  document.head.appendChild(style)
  return iframe
}

/**
 * This is a patched version of the native `import` method that works with vite bundling. Allows you
 * to import a javascript file from the public directory.
 */
const importStatic = (modulePath: string) => {
  if (import.meta.env.DEV) {
    return import(/* @vite-ignore */ `${modulePath}?${Date.now()}`)
  } else {
    return import(/* @vite-ignore */ modulePath)
  }
}

let captureException: (exception: unknown, hint?: EventHint) => string = () => {
  console.error('captureException not initialized yet')
  return ''
}

const initSentry = () => {
  // prevent embed sentry to conflict with sentry on hosted website.
  // we should export things like captureException from this client instead of using the one
  // from sentry directly.
  const client = new BrowserClient({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: sentry.env,
    release: process.env.SENTRY_RELEASE_COMMIT,
    tunnel: `${endpoint.track()}/sentry/tunnel`,
    // remove integrations that use global sentry object https://docs.sentry.io/platforms/javascript/best-practices/multiple-sentry-instances/
    integrations: getDefaultIntegrations({}).filter(defaultIntegration => {
      return !['BrowserApiErrors', 'Breadcrumbs', 'GlobalHandlers'].includes(
        defaultIntegration.name
      )
    }),
    transport: makeFetchTransport,
    stackParser: defaultStackParser,

    beforeSend(event) {
      const eventFrames = event.exception?.values?.flatMap(
        exception => exception.stacktrace?.frames ?? []
      )

      // ignore root-level errors are they are not related to snippet
      if (!eventFrames?.length) {
        return null
      }

      // ignores anything that is not happening in our code
      if (
        !eventFrames.every(eventFrame =>
          eventFrame.filename?.includes('/chat/embed/assets/index.js')
        )
      ) {
        return null
      }

      return event
    },
  })

  const scope = new Scope()
  scope.setClient(client)
  client.init()

  captureException = scope.captureException.bind(scope)
}

const init = async () => {
  await importStatic(`${import.meta.env.BASE_URL}env-config.js`)

  initSentry()

  const workspaceId = window.ChiliChat?.config.workspaceId
  const tenantId = window.ChiliChat?.config.tenantId
  const playbookId = window.ChiliChat?.config.playbookId
  const zIndex = window.ChiliChat?.config.zIndex

  if (typeof workspaceId !== 'string') {
    return console.error(`workspaceId was invalid, expected string, received ${workspaceId}`)
  }

  if (typeof tenantId !== 'string') {
    return console.error(`tenantId was invalid, expected string, received ${tenantId}`)
  }

  const iframe = createChatIframe(workspaceId, tenantId, playbookId, zIndex)
  document.body.appendChild(iframe)

  const {
    unsubscribe: unsubscribeScrollPercentage,
    reset: resetScrollPercentage,
    onIframeInit: onIframeInitScrollPercentage,
  } = storeAndSendMaxScrollPercentage(iframe)
  const {
    unsubscribe: unsubscribeUserTimeOnPage,
    reset: resetUserTimeOnPage,
    onIframeInit: onIframeInitUserTimeOnPage,
  } = storeAndSendUserTimeOnPage(iframe)
  const onPathnameChange = () => {
    resetScrollPercentage()
    resetUserTimeOnPage()
  }
  const { onHrefChanged } = storeAndSendHref(iframe)

  window.addEventListener('message', (message: MessageEvent<unknown>) => {
    const isFromIframe = message.origin === iframeOrigin
    const data = validataMessageFormat(message.data)
    if (!isFromIframe || !data) {
      return
    }
    if (data.type === 'WIDGET_COMMUNICATION_READY') {
      onIframeInitUserTimeOnPage()
      onIframeInitScrollPercentage()
      listenOnUrlUpdates({
        onHrefChanged,
        onPathnameChanged: onPathnameChange,
      })
      trackActivity(iframe)
      sendIsNarrowScreen(iframe)
      syncPageVisibility(iframe)
    } else {
      handleMessage(data, {
        iframe,
        unsubscribeScrollPercentage,
        unsubscribeUserTimeOnPage,
      })
    }
  })
}

try {
  init()
} catch (e) {
  captureException(e)
  console.error(e)
}

const trackActivity = (iframe: HTMLIFrameElement) => {
  const trackThrottled = R.funnel(
    () => {
      sendMessageToIframe({ type: 'GUEST_ACTIVITY' }, iframe)
    },
    { minGapMs: 10 * 1000, triggerAt: 'start' }
  )

  handlePageActivity(trackThrottled.call)
}

const validataMessageFormat = (data: unknown): MessageToEmbed | undefined => {
  const isValidFormat = typeof data === 'object' && data
  if (!isValidFormat) {
    return
  }
  const isValidSource = 'source' in data && data.source === 'chili-chat-iframe'
  if (!isValidSource) {
    return
  }
  return data as MessageToEmbed
}
